import {Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Inject} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslationService} from '../../helpers/translation.service';
import {ApiCallService} from '../../helpers/api-call.service';
import * as aux from 'adcore-auxiliary';
import {WindowRefService} from 'adcore-auxiliary';
import {Subject} from 'rxjs';
import {MenuItem} from 'primeng/api';
import data from '../../shared/googleCodes.json';
import * as cfg from '../../helpers/config';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as utils from '../../helpers/utils';
import {DOCUMENT} from '@angular/common';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  MAX_FILE_SIZE = 5 * 1024 * 1024;
  activeIndex = 0;
  stepItems: MenuItem[] = [];
  inProcess = false;
  firstNameError = false;
  lastNameError = false;
  countryError = false;
  businessTypeError = false;
  selectedMediaTypesError = false;
  companyNameError = false;
  industryError = false;
  spendError = false;
  emailError: string | null = null;
  phoneError: string | null = null;
  websiteError: string | null = null;
  fileError: string | null = null;
  uploadedFile: File | null = null;
  fileName: string | null = null;
  submitted = true;
  submittedError = '';
  @ViewChild('phone') phone: aux.PhoneComponent | undefined;
  formInfo: any = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    selectedMediaTypes: [],
    spend: '',
    companyCountry: '',
    businessType: '',
    website: '',
    companyName: '',
    industry: [],
    utmSource: '',
    utmMedium: '',
    contactType: '',
    contactValue: ''
  };
  mediaTypes = [
    {value: 'Google Ads', label: 'GOOGLE_ADS'},
    {value: 'Microsoft Ads', label: 'MICROSOFT_ADS'},
    {value: 'Facebook', label: 'FACEBOOK'},
    {value: 'TikTok ads', label: 'TIKTOK_ADS'},
    {value: 'Remarketing', label: 'REMARKETING'},
    {value: 'Native', label: 'NATIVE'},
    {value: 'DSP', label: 'DSP'},
    {value: 'Other', label: 'OTHER'}
  ];
  spends = [
    {value: 'less than $10,000', label: '', id: 'LESS_THAN_10K'},
    {value: '$10,000-$25,000', label: '', id: '$10,000-$25,000'},
    {value: '25,000$-50,000$', label: '', id: '$25,000-$50,000'},
    {value: '50,000$-100,000$', label: '', id: '$50,000-$100,000'},
    {value: '100,000$-150,000$', label: '', id: '$100,000-$150,000'},
    {value: 'more than $150,000', label: '', id: 'MORE_THAN_150K'}
  ];
  countries: any[] = [];
  businessTypes = [
    {label: 'Agency', value: 'Agency', id: 'AGENCY'},
    {label: 'Company', value: 'Company', id: 'COMPANY'},
    {label: 'Freelancer', value: 'Freelancer', id: 'FREELANCER'},
  ];
  // For all drop down options, the label does not matter. The id is key for translation (label will be updated with the
  // translated result). value is used in server side to store in db and hubspot.
  furtherContactTypes = [
    {label: 'WhatsApp', value: 'WhatsApp', id: 'WHATSAPP'},
    {label: 'Telegram', value: 'Telegram', id: 'TELEGRAM'},
    {label: 'Skype', value: 'Skype', id: 'SKYPE'},
    {label: 'WeChat', value: 'WeChat', id: 'WECHAT'},
  ];
  industries = [
    {label: 'AFFILIATE', value: 'Affiliate'},
    {label: 'CRYPTO', value: 'Crypto'},
    {label: 'TRAVEL', value: 'Travel'},
    {label: 'IT_SUPPORT_SERVICE', value: 'IT_support'},
    {label: 'GAMBLING', value: 'Gambling'},
    {label: 'DROPSHIPPING', value: 'Dropshipping'},
    {label: 'E-COMMERCE', value: 'E-Commerce'},
    {label: 'GAMING', value: 'Gaming'},
    {label: 'FINANCE', value: 'Finance'},
    {label: 'OTHER', value: 'Other'},
    ];
  currentCountryCode = 'US';
  currentLanguageKey = 'en';

  languageMenuItems: any[] = [];
  updateInProcess = false;
  responsiveOptions = [
    {
      breakpoint: '850px',
      numVisible: 2,
      numScroll: 1
    }
  ];

  constructor(private apiCall: ApiCallService, public winRef: WindowRefService, private ref: ChangeDetectorRef,
              private translationService: TranslationService, private route: ActivatedRoute, private router: Router,
              private deviceService: DeviceDetectorService, @Inject(DOCUMENT) private _document: any) {
    this.router.events
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          utils.setAppFavIcon(this._document, 'mediablast');
        }
      });
  }

  get isDesktopDevice(): boolean {
    return this.deviceService.isDesktop() || this.deviceService.isTablet();
  }
  get contactValuePlaceHolder(): any {
    if (this.formInfo.contactType === 'WhatsApp') {
      return 'WHATSAPP_NUMBER';
    } else {
      return this.formInfo.contactType.toUpperCase() + '_ID';
    }
  }
  get websiteTranslationKey(): string {
    return this.formInfo.businessType === 'Freelancer' ? 'LINKEDIN_FACEBOOK_PROFILE' : 'WEBSITE';
  }
  onMenuShow(event: any): void {
    setTimeout(() => {
      // solution to fix the issue with selected menu item when open the menu
      this.winRef.nativeDocument?.activeElement?.blur();
    });
  }
  ngOnInit(): void {
    this.winRef.reportGtagEventNew('click-on-apply-now', {});
    this.countries = Array.from(data.countries,
        x => ({label: x['country name'], value: x['country name'], code: x['country code']}));
    this.languageMenuItems = this.languages;
    const nativeGeoIp = this.winRef.nativeGeoIp;
    this.formInfo.utmSource = this.route.snapshot.queryParams.utm_source || '';
    this.formInfo.utmMedium = this.route.snapshot.queryParams.utm_medium || '';
    if (nativeGeoIp) {
      nativeGeoIp.country((geoipResponse: any) => {
          // console.log('Country', geoipResponse.country.iso_code);
          this.currentCountryCode = geoipResponse.country.iso_code;
          const country = this.countries.find(c => c.code === this.currentCountryCode);
          this.formInfo.companyCountry = country ? country.value : '';
          this.getLanguageKey().then(() => {});
        },
        (err: any) => {
          console.log('Error', err);
        });
    }
    this.update(this.currentLanguageKey);
    this.stepItems = [
      {label: '1',
        styleClass: 'check'
  },
      {label: '2',
        styleClass: 'check'
      },
    ];
    this.activeIndex = 0;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  private translateLabels(items: any[]): void {
    items?.forEach((item: any) => {
      if (item && item.id){
        item.label = this.translate(item.id);
      }
    });
  }

  onActiveIndexChange(event: any): void {
    // console.log(`Stage Jump to event ${event}`);
    if (event <= this.activeIndex || (this.validate() && !this.checkDisabled(event))){
      this.activeIndex = event;
    }
  }

  private async getLanguageKey(): Promise<void> {
    if (this.currentCountryCode !== 'US') {
      const result = await this.apiCall.getKycLanguage(this.currentCountryCode).toPromise();
      if (result) {
        this.currentLanguageKey = result;
      }
    }
    // console.log(this.currentCountryCode, this.currentLanguageKey);
  }

  translate(key: string): string {
    return this.translationService.translate(key);
  }

  private get languages(): MenuItem[] {
    return cfg.SYSTEM_LANGUAGES.map((lang: any) => {
      return {
        label: this.translate(lang.value),
        id: lang.key,
        automationId: lang.key,
        icon: lang.key === this.currentLanguageKey ? 'pi pi-check' : 'pi',
        command: (event: any) => this.update(event?.item?.automationId)
      };
    });
  }

  get currentLanguage(): string {
    const lang = cfg.SYSTEM_LANGUAGES.find((l: any) => l.key === this.currentLanguageKey);
    return this.translate(lang?.value || 'ENGLISH');
  }

  private async update(language: string): Promise<void> {
    if (language) {
      this.updateInProcess = true;
      const translationResult = await this.apiCall.getTranslations(language).toPromise();
      if (translationResult && !translationResult.err) {
        this.translationService.translations = translationResult;
        this.translationService.languageChange.emit(language);
        this.currentLanguageKey = language;
        this.translateLabels(this.businessTypes);
        this.translateLabels(this.spends);
        this.translateLabels(this.furtherContactTypes);
        this.languageMenuItems = this.languages;
      }
      this.updateInProcess = false;
    }
  }

  next(): void {
    // console.log({a: this.formInfo.phoneNumber, b: this.phone?.fullPhoneNumber?.internationalNumber});
    if (this.formInfo.businessType === 'Freelancer') {
      this.formInfo.companyName = `${this.formInfo.firstName} ${this.formInfo.lastName} (${this.formInfo.email})`;
    }
    if (this.validate()) {
      this.winRef.reportGtagEventNew('finished-first-step', {});
      this.formInfo.step = this.activeIndex;
      this.apiCall.createOrUpdateContact(JSON.stringify(this.formInfo)).subscribe();
      this.stepItems[this.activeIndex].label = '';
      this.stepItems[this.activeIndex].styleClass = 'icon check';
      this.activeIndex = this.activeIndex + 1;
    }
  }

  submit(): void {
    this.inProcess = true;
    if (this.validate()) {
      this.winRef.reportGtagEventNew('submitted-kyc-form', {});
      const formData = new FormData();
      if (this.fileName) {
        this.formInfo.fileName = this.fileName;
      }
      this.formInfo.step = this.activeIndex;
      formData.append('file', this.uploadedFile as File);
      formData.append('form', JSON.stringify(this.formInfo));
      this.apiCall.sendKycForm(formData).subscribe((res: any) => {
        if (res.result && res.result === 'success') {
          this.submitted = true;
        } else {
          this.submitted = false;
          this.submittedError = res.error;
        }
      }, (err: any) => {
        this.submitted = false;
        this.submittedError = err;
      },() => {
        this.activeIndex = 2;
        this.inProcess = false;
      });
    } else {
      this.inProcess = false;
    }
  }

  checkDisabled(step: number): boolean {
    let disabled = false;
    const disableStep1 =  [this.formInfo.firstName.length, this.formInfo.lastName.length,  this.formInfo.email.length,
      this.formInfo.phoneNumber.length, this.formInfo.companyCountry.length, this.formInfo.businessType.length];
    const disabledArray = [disableStep1,
      [this.formInfo.selectedMediaTypes.length, this.formInfo.industry.length, this.formInfo.spend.length]
      ];
    for (let i = 0; i < step; i++) {
      if (disabledArray[i].includes(0)) {
        disabled = true;
        break;
      }
    }
    return disabled;
  }

  validate(): boolean {
    this.emailError = null;
    this.phoneError = null;
    this.websiteError = null;

    this.firstNameError = !(this.formInfo.firstName && this.formInfo.firstName.length);
    this.lastNameError =  !(this.formInfo.lastName && this.formInfo.lastName.length);
    this.countryError =  !(this.formInfo.companyCountry && this.formInfo.companyCountry.length);
    this.businessTypeError = !(this.formInfo.businessType && this.formInfo.businessType.length);
    this.companyNameError = this.formInfo.businessType !== 'Freelancer' && !(this.formInfo.companyName && this.formInfo.companyName.length);

    if (this.formInfo.email.length === 0) {
      this.emailError = this.translate('INVALID_FIELD');
    } else {
      const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
      if (!re.test(this.formInfo.email || '')) {
        this.emailError = this.translate('EMAIL_ERROR');
      }
    }

    if (this.formInfo.phoneNumber.length === 0) {
      this.phoneError = this.translate('INVALID_FIELD');
    } else {
      if (Boolean(aux.phoneNumberValidator(this.phone))) {
        if (this.phone?.fullPhoneNumber) {
          this.phoneError = this.translate('PHONE_ERROR');
          this.phone.fullPhoneNumber.internationalNumber = null;
        }
      }
    }

    if (this.formInfo.website.length === 0) {
      this.websiteError = this.translate('INVALID_FIELD');
    } else {
      const re2 = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$');
      if (!re2.test(this.formInfo.website || '')) {
        this.websiteError = this.translate('WEBSITE_ERROR');
      }
    }

    if (this.activeIndex === 1) {
      this.selectedMediaTypesError = !(this.formInfo.selectedMediaTypes && this.formInfo.selectedMediaTypes.length);
      this.industryError = !(this.formInfo.industry && this.formInfo.industry.length);
      this.spendError = !(this.formInfo.spend && this.formInfo.spend.length);
    }
    return Boolean(!this.firstNameError && !this.lastNameError && !this.countryError && !this.businessTypeError &&
    !this.companyNameError && !this.emailError && !this.phoneError && !this.websiteError && !this.selectedMediaTypesError
      && !this.industryError && !this.spendError);
  }

  onPhoneOnChange(event: any): void {
    this.formInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || this.phone?.fullPhoneNumber?.number || '';
    this.phoneError = null;
  }

  removeError(event: string): void {
    if (event === 'website') {
      this.websiteError = null;
    }
    if (event === 'email') {
      this.emailError = null;
    }
  }

  public goBack(): void {
    this.activeIndex = this.activeIndex - 1;
    this.stepItems[this.activeIndex].label = (this.activeIndex + 1).toString();
    this.stepItems[this.activeIndex].styleClass = 'check';
    this.ref.detectChanges();
  }

  public onChooseFile(files: any): void {
    this.fileError = null;
    if (files.target.files.length) {
      if (files.target.files[0].size > this.MAX_FILE_SIZE) {
        this.fileError = this.translate('FILE_SIZE_ERROR');
      } else {
        this.uploadedFile = files.target.files[0];
        this.fileName  = this.uploadedFile?.name || null;
      }
    }
  }

  public onClick(event: any): void {
    event.target.value = null;
  }

  clearFile(): void {
    this.uploadedFile = null;
    this.fileName = null;
  }
}
