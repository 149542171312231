import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './profile/login/login.component';
import * as aux from 'adcore-auxiliary';
import {ApiCallService} from './helpers/api-call.service';
import {MediaService} from './helpers/media.service';
import {AuthenticationService} from './helpers/authentication.service';
import {InputTextModule} from 'primeng/inputtext';
import {DividerModule} from 'primeng/divider';
import {PasswordModule} from 'primeng/password';
import {ButtonModule} from 'primeng/button';
import {DEFAULT_CONTENT_TYPE, HeadersInterceptor} from './helpers/headers.interceptor';
import {MessagingService} from './helpers/messaging.service';
import {ProfileLayoutComponent} from './profile/profile-layout.component';
import {JoinComponent} from './profile/join/join.component';
import {MypassComponent} from './profile/mypass/mypass.component';
import {SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import {GoogleLoginProvider, FacebookLoginProvider} from '@abacritt/angularx-social-login';
import {LogoutComponent} from './profile/logout.component';
import {UnsubscribeComponent} from './profile/unsubscribe/unsubscribe.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {TranslationService} from './helpers/translation.service';
import {UsageService} from './helpers/usage.service';
import {Userpilot} from 'userpilot';
import {MerchantService} from './helpers/merchant.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DeviceErrorComponent} from './device-error/device-error.component';
import {ImageModule} from 'primeng/image';
import {DigitOnlyModule} from '@uiowa/digit-only';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {AvatarModule} from 'primeng/avatar';
import {CookieService} from 'ngx-cookie-service';
import {IgnoreComponent} from './alerter/ignore/ignore.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {PromotionComponent} from './promotion/promotion.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {AuditRequestComponent} from './audit-request/audit-request.component';
import {ChipsModule} from 'primeng/chips';
import {MobileMyCloudComponent} from './mobile-my-cloud/mobile-my-cloud.component';
import {MobileVideoItemComponent} from './mobile-my-cloud/video-item/video-item.component';
import {ToolbarModule} from 'primeng/toolbar';
import {SkeletonModule} from 'primeng/skeleton';
import {CheckboxModule} from 'primeng/checkbox';
import { InputOtpModule } from 'primeng/inputotp';
import {DialogModule} from 'primeng/dialog';
// Initialize Userpilot
Userpilot.initialize('NX-501b11f7');

export const AuthProviderConfig = {
    provide: 'SocialAuthServiceConfig',
    useValue: {
        autoLogin: false,
        providers: [
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider('230530490489-nqm7nta5rcvsbnmutuu62kl8edgnrjo9.apps.googleusercontent.com',
                    {
                        oneTapEnabled: false,
                        scopes: ['https://www.googleapis.com/auth/userinfo.email',
                            'https://www.googleapis.com/auth/userinfo.profile', 'openid']
                    })

            },
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider('263406928369483')
            }
        ]
    } as SocialAuthServiceConfig,
};

@NgModule({
  declarations: [
        AppComponent,
        ProfileLayoutComponent,
        LoginComponent,
        JoinComponent,
        MypassComponent,
        LogoutComponent,
        UnsubscribeComponent,
        DeviceErrorComponent,
        IgnoreComponent,
        PromotionComponent,
        AuditRequestComponent,
        MobileMyCloudComponent,
        MobileVideoItemComponent
    ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        InputTextModule,
        PasswordModule,
        ButtonModule,
        DividerModule,
        ProgressSpinnerModule,
        DynamicDialogModule,
        aux.AdcoreAuxiliaryModule,
        ImageModule,
        GoogleSigninButtonModule,
        DigitOnlyModule,
        CardModule,
        CarouselModule,
        AvatarModule,
        RadioButtonModule,
        ProgressBarModule,
        ChipsModule,
        ToolbarModule,
        SkeletonModule,
        CheckboxModule,
        ToastModule,
    InputOtpModule,
    DialogModule],
  providers: [
    AuthProviderConfig, TranslationService, aux.WindowRefService, ApiCallService, DialogService, MessageService,
    UsageService, DeviceDetectorService, MediaService, AuthenticationService, MessagingService, MerchantService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: aux.ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: aux.TimeoutInterceptor, multi: true },
    { provide: aux.DEFAULT_TIMEOUT, useValue: 120000 },
    { provide: DEFAULT_CONTENT_TYPE, useValue: 'application/json' }, provideHttpClient(withInterceptorsFromDi())
  ] })
export class AppModule {
}
