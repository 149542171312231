<div class="kyc" [attr.isDesktop]="isDesktopDevice">
  <div class="left-panel" *ngIf="isDesktopDevice">
    <div class="top">
      <a href="https://www.adcore.com/technologies/media-blast" style="display: block">
        <span class="logo"></span>
        <label style="cursor: pointer">{{translate('MEDIA_BLAST')}}</label>
      </a>
    </div>
    <div class="middle">
      <label class="title">{{translate("MB_KYC_LEFT_TITLE")}}</label>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_ONE")}}</div>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_TWO")}}</div>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_THREE")}}</div>
    </div>
    <div class="bottom">
      <!--<div class="ad-logo"></div> -->
      <div class="text">Accounts we provide</div>
      <div class="logos">
        <a class="logo-1" href="https://www.google.com/partners/agency?id=3987411150" target="_blank"><i></i></a>
        <a class="logo-2" href="https://www.facebook.com/business/partner-directory/search?id=1731416420349160" target="_blank"><i></i></a>
        <a class="logo-3" href="https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc" target="_blank"><i></i></a>
        <div class="middle-section">
          <a class="logo-4"><i></i></a>
          <a class="logo-5"><i></i></a>
          <a class="logo-6"><i></i></a>
          <a class="logo-7"><i></i></a>
        </div>
        <div>
          <a class="logo-8"><i></i></a>
          <a class="logo-9"><i></i></a>
          <a class="logo-10"><i></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-view-top" *ngIf="!isDesktopDevice">
    <div class="top">
      <span class="logo"></span>
      <label>{{translate('MEDIA_BLAST')}}</label>
    </div>
  </div>
  <div class="main-box">
    <div class="main-form" [ngClass]="activeIndex === 2 ? 'success-step-align' : ''" style="overflow: scroll;">
      <div class="form-steps" *ngIf="activeIndex !== 2 && !updateInProcess">
        <div class="card">
          <p-button styleClass="back-btn" *ngIf = "activeIndex > 0" (onClick)="goBack()"><img src="/assets/icons/arrow-back-grey.svg"><span>{{translate('BACK')}}</span></p-button>
          <p-steps [model]="stepItems" [readonly]="false" [activeIndex]="activeIndex" styleClass="steps-wiz"
                   (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
        </div>
        <div *ngIf="activeIndex == 0">
          <ng-container>
            <h3> {{translate("APPLY_TO_MEDIA_BLAST")}}</h3>
            <h2>{{translate("YOUR_PERSONAL_DETAILS")}}</h2>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('FIRSTNAME') + '*'}}</div>
              <input id="firstName" name="firstName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('FIRSTNAME') + '*' : ''"
                     [(ngModel)]="formInfo.firstName" autocomplete="off" [required]="true" autofocus
                     [disabled]="inProcess" (keyup)="firstNameError=false"/>
              <small class="login-error mb-1" id="first-name-error" *ngIf="firstNameError">{{translate("INVALID_FIELD")}}</small>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('LASTNAME') + '*'}}</div>
              <input id="lastName" name="lastName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('LASTNAME') + '*' : ''"
                     [(ngModel)]="formInfo.lastName" autocomplete="off" [required]="true" [disabled]="inProcess" (keyup)="lastNameError=false"/>
              <small class="login-error mb-1" id="last-name-error" *ngIf="lastNameError">{{translate("INVALID_FIELD")}}</small>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">Phone number*</div><!--TODO: add to translation-->
              <aux-phone #phone [countryCode]="currentCountryCode"  [modelPhoneNumber]="formInfo.phoneNumber"
                         (phoneChangeEvent)="onPhoneOnChange($event)" [classStyle]="'purple-darker-palette'"
                         [showExamplePlaceholder]="false" [placeholder]="translate('NG_BC_PHONE_NUMBER')"
                         [required]="true" [autofocus]="false"></aux-phone>
              <small class="login-error mb-1" id="phone-error" *ngIf="phoneError">{{phoneError}}</small>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('EMAIL_PLACEHOLDER') + '*'}}</div>
              <input id="email" name="email" type="text" pInputText [placeholder]="isDesktopDevice ? translate('EMAIL_PLACEHOLDER') + '*' : ''"
                     [(ngModel)]="formInfo.email" autocomplete="off" [required]="true" [disabled]="inProcess" (keyup)="removeError('email')"/>
              <small class="login-error mb-1" id="email-error" *ngIf="emailError">{{emailError}}</small>
            </div>
            <div class="field">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('FURTHER_CONTACT_DETAILS')}}</div>
              <p-dropdown styleClass="w-100" [options]="furtherContactTypes" [(ngModel)]="formInfo.contactType" optionLabel="label"
                          optionValue="value" [placeholder]="translate('FURTHER_CONTACT_DETAILS')"></p-dropdown>
            </div>
            <div *ngIf="formInfo.contactType.length" class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate(contactValuePlaceHolder)}}</div>
              <input id="contactValue" name="contactValue" type="text" pInputText [placeholder]="isDesktopDevice ? translate(contactValuePlaceHolder) : ''"
                     [(ngModel)]="formInfo.contactValue" autocomplete="off" [required]="true"
                     [disabled]="inProcess"/>
            </div>
            <div class="field">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COUNTRY') + '*'}}</div>
              <p-dropdown styleClass="w-100 countries" [options]="countries" [(ngModel)]="formInfo.companyCountry" optionLabel="label"
                          optionValue="value" [placeholder]="translate('COUNTRY') + '*'"></p-dropdown>
              <small class="login-error mb-1" id="company-country-error" *ngIf="countryError">{{translate("INVALID_FIELD")}}</small>
            </div>
            <h2>{{translate('COMPANY_DETAILS')}}</h2>
            <div class="field">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('BUSINESS_TYPE') + '*'}}</div>
              <p-dropdown styleClass="w-100" [options]="businessTypes" [(ngModel)]="formInfo.businessType" optionLabel="label"
                          optionValue="value" [placeholder]="translate('BUSINESS_TYPE') + '*'" (onChange)="businessTypeError=false"></p-dropdown>
              <small class="login-error mb-1" id="business-type-error" *ngIf="businessTypeError">{{translate("INVALID_FIELD")}}</small>
            </div>
            <div *ngIf="formInfo.businessType && formInfo.businessType !== 'Freelancer'">
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COMPANY_NAME') + '*'}}</div>
                <input id="companyName" name="companyName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('COMPANY_NAME') + '*' : ''"
                       [(ngModel)]="formInfo.companyName" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
                <small class="login-error mb-1" id="company-name-error" *ngIf="companyNameError">{{translate("INVALID_FIELD")}}</small>
              </div>
            </div>
            <div class="field fluid mb-3" *ngIf="formInfo.businessType">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate(websiteTranslationKey) + '*'}}</div>
              <input id="website" name="website" type="text" pInputText [placeholder]="isDesktopDevice ? translate(websiteTranslationKey) + '*': ''"
                     [(ngModel)]="formInfo.website" autocomplete="off" [required]="true" (keyup)="removeError('website')"
                     [disabled]="inProcess"/>
              <small class="login-error mb-1" id="website-error" *ngIf="websiteError">{{websiteError}}</small>
            </div>

          </ng-container>
          <div class="btn-box">
            <p-button styleClass="next-btn" [label]="translate('CONTINUE')" (onClick)="next()"></p-button>
          </div>
        </div>
        <div class="media" *ngIf="activeIndex == 1">
          <h3>{{translate('YOUR_MEDIA_NEEDS')}}</h3>
          <div>
            <h4>{{translate('DESIRED_CHANNELS')}}*</h4>
            <div class="checkbox-container">
              <div *ngFor="let mediaType of mediaTypes" class="checkbox-item">
                <div>
                  <p-checkbox name="mediaType" value="mediaType" [value]="mediaType.value"
                              [(ngModel)]="formInfo.selectedMediaTypes" [inputId]="mediaType.value" (onChange)="selectedMediaTypesError=false"></p-checkbox>
                  <label [for]="mediaType.value">{{translate(mediaType.label)}}</label>
                </div>
              </div>
            </div>
            <small class="login-error mb-1" id="selected-media-types-error" *ngIf="selectedMediaTypesError">{{translate("INVALID_FIELD")}}</small>
          </div>
          <div>
            <h4>{{translate('INDUSTRY')}}*</h4>
            <div class="checkbox-container">
              <div *ngFor="let industry of industries" class="checkbox-item">
                <p-checkbox name="industry" [value]="industry.value"  [(ngModel)]="formInfo.industry" [inputId]="industry.value"
                            (onChange)="industryError=false"></p-checkbox>
                <label [for]="industry.value">{{translate(industry.label)}}</label>
              </div>
              <small class="login-error mb-1" id="industry-error" *ngIf="industryError">{{translate("INVALID_FIELD")}}</small>
            </div>
          </div>
          <div class="spend">
            <h4>{{translate('TOTAL_MONTHLY_AD_SPEND')}}*</h4>
            <div>
              <p-dropdown styleClass="w-100" [options]="spends" [(ngModel)]="formInfo.spend" optionLabel="label"
                          optionValue="value" [placeholder]="translate('SELECT_AD_SPEND')" (onChange)="spendError=false"></p-dropdown>
            </div>
            <small class="login-error mb-1" id="spend-error" *ngIf="spendError">{{translate("INVALID_FIELD")}}</small>
          </div>
          <div class="btn-box">
            <p-button styleClass="next-btn" [label]="translate('SUBMIT')" [disabled]="inProcess" (onClick)="submit()"></p-button>
          </div>
        </div>
      </div>
      <div class="success-step" *ngIf="activeIndex == 2 && !updateInProcess">
        <div class="top-section">
          <div>
            <div class="success-icon"><i></i></div>
          </div>
          <h3>{{translate('THANK_YOU')}}</h3>
          <div class="submit-text">
            <p *ngIf="submitted">{{translate('SUBMIT_SUCCESS_INFO')}}</p>
            <p *ngIf="!submitted">{{translate('SUBMIT_ERROR_INFO')}}</p>
          </div>
        </div>
      </div>
      <div class="center-container" *ngIf="updateInProcess">
        <p-progressSpinner ></p-progressSpinner>
      </div>
    </div>

    <div class="footer">
      <div class="inline-flex footer-text">
        <a href="https://www.adcore.com/privacy-policy/" style="text-decoration:none" target="_blank">Privacy Policy</a>
        <span class="separator">|</span>
        <a href="https://www.adcore.com/terms-of-use/" style="text-decoration:none" target="_blank">Terms of Use</a>
      </div>
      <div class="translate-drop inline-flex">
        <p-menu styleClass="globe-lang-menu" #menu [popup]="true" [model]="languageMenuItems" appendTo="body" (onShow)="onMenuShow($event)"></p-menu>
        <p-button styleClass="globe-btn" [icon]="!updateInProcess ? 'pi pi-globe' : 'pi pi-spin pi-globe'"
                  [label]="currentLanguage" (click)="menu.toggle($event)"></p-button>
      </div>
    </div>
  </div>
</div>
